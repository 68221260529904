import styled from "styled-components"

export const $WorkHere = styled.div`
  padding: 3em 0;
  position: relative;

  .penta-background {
    position: absolute;
    z-index: -2;
    top: -3em;
    left: 50%;
    transform: translateX(-50%);
    @media only screen and (max-width: 1024px) {
      top: 0;
      width: 100%;
      svg {
        width: 100%;
        height: 75vw;
      }
    }
  }

  .alveol-background {
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: 0;
  }

  .main-container {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;

    .title-container-video {
      width: 90%;
      margin: 2em 0;
      h3 {
        /* font-size: 2.5rem;
        font-weight: bold; */
        color: #fa280c;
        text-align: center;
      }
    }
    .title-container {
      width: 90%;
      margin: 5em 1em 1em;
      text-align: center;

      @media only screen and (max-width: 768px) {
        margin: 5em 1em 3em;
      }

      h3 {
        /* font-size: 2.5rem;
        font-weight: bold; */
        color: #fa280c;
      }
      span {
        max-width: 80%;
        width: 45em;
        padding: 1em 1em 2em;

        &.subTitle {
          padding: 0;
          color: #fa280c;
          font-weight: bold;
        }
      }
    }
    .why-list {
      margin: 0em 0 8em;
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @media only screen and (max-width: 768px) {
        justify-content: center;
      }

      .list {
        padding: 2em;
        width: 50%;
        height: fit-content;

        @media only screen and (max-width: 768px) {
          width: 100%;
          padding: 1em;
        }

        .content-box {
          padding: 2em;
        }
        h4 {
          font-size: 2em;
        }
      }
      .number {
        padding: 0.2em;
        font-size: 5rem;
        font-weight: 900;
        line-height: 0;
      }
      .list-01 {
        color: #292625;
        .number {
          color: #b6b7ba;
        }
        .content-box {
          background-color: #ecedf3;
        }
      }
      .list-02 {
        color: #fff;

        text-align: right;
        .number {
          color: #3b466a;
        }
        .content-box {
          text-align: left;
          background-color: #3b466a;
        }
      }
      .list-03 {
        color: #fff;
        text-align: right;

        .number {
          color: #fa280c;
        }
        .content-box {
          text-align: left;
          background-color: #fa280c;
        }
        /* @media only screen and (min-width: 769px) {
          transform: translateY(-8.2em);
        } */
      }
      .list-04 {
        color: #292625;

        .number {
          color: #b6b7ba;
        }
        .content-box {
          background-color: #ecedf3;
        }
      }
    }
  }
  .video {
    width: 64vw;
    height: 36vw;
    position: relative;

    @media only screen and (max-width: 1024px) {
      width: 80vw;
      height: 45vw;
    }
    @media only screen and (max-width: 768px) {
      width: 88vw;
      height: 49.5vw;
    }
    @media only screen and (max-width: 425px) {
      width: 96vw;
      height: 54vw;
    }
  }
  .btn {
    position: absolute;
    top: 50%;
    background: none;
    border: none;
    transform: translateY(-50%);
    width: 10%;
    min-width: 75px;
    &.right {
      right: 0;
      transform: rotate(180deg) translateY(50%);
    }
  }
  .react-player__preview,
  iframe {
    border-radius: 0.5em;
  }
`
