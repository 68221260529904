import styled from "styled-components";

export const $NosValeurs = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 0 5em;
  flex-direction: column;
  align-items: center;
  margin: 3em auto;
  background: url(./../../../SVG/Trace_884.svg) no-repeat center top;
  background-size: 65%;
  @media only screen and (max-width: 1440px) {
    padding: 0%;
  }
  @media only screen and (min-width: 767px) {
    &:before {
      content: "";
      position: absolute;
      top: -10%;
      right: 0%;
      width: 25%;
      height: 29em;
      background: url(./../../../STAS-Louis-atelier-collegue.webp) no-repeat
        center center;
      background-size: contain;
    }
  }

  .title {
    margin-top: 2em;
    font-size: 2.9em;
    color: #fe2b09;
    font-weight: bold;
  }
  p {
    width: 50%;
    text-align: center;

    @media only screen and (max-width: 425px) {
      width: 90%;
    }
  }
  .container-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 85%;
    margin: 4em auto;

    @media only screen and (max-width: 425px) {
      width: 100%;
    }
  }
`;

export const $valeurElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 0;
  position: relative;
  height: auto;
  margin: 0.5em;
  background: ${(props) =>
    props.backgroundImg
      ? `url(./../../../SVG/${props.backgroundImg}.svg) no-repeat center center`
      : ""};

  background-size: contain;
  width: 31%;
  padding-top: 34.8%;

  @media only screen and (max-width: 1024px) {
    width: 45%;
    padding-top: 50.5%;
  }
  @media only screen and (max-width: 768px) {
    width: 65%;
    padding-top: 73%;
  }
  @media only screen and (max-width: 580px) {
    width: 75%;
    padding-top: 84%;
  }
  @media only screen and (max-width: 425px) {
    width: 84%;
    padding-top: 94%;
  }
  @media only screen and (max-width: 375px) {
    width: 95%;
    padding-top: 106.5%;
  }

  .content {
    /* transform: translateY(-10%); */
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 425px) {
      padding: 1em 0;
      width: 100%;
    }

    .icon-nos-valeur {
      margin: 10% 0 1%;

      @media only screen and (max-width: 1024px) {
        margin: 8% 0 2%;
      }
      @media only screen and (max-width: 768px) {
        margin: 10% 0 2%;
      }
      @media only screen and (max-width: 425px) {
        margin: 10% 0 2%;
      }
      @media only screen and (max-width: 375px) {
        margin: 10% 0 2%;
      }
      svg {
        height: 4em;

        @media only screen and (max-width: 1024px) {
          height: 5em;
        }
        @media only screen and (max-width: 768px) {
          height: 5.5em;
        }

        @media only screen and (max-width: 425px) {
          height: 5em;
        }
        @media only screen and (max-width: 375px) {
          height: 5em;
        }

        .trace837 {
          fill: ${(props) => (props.color ? props.color : `#fff`)};
        }
      }
    }

    .description {
      color: ${(props) => (props.color ? `#000` : `#fff`)};
      text-align: center;
      font-size: 1.4vw;
      width: 90%;
      @media only screen and (min-width: 1441px) {
        font-size: 1.25vw;
      }
      @media only screen and (max-width: 1440px) {
        font-size: 1.4vw;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 2.1vw;
      }
      @media only screen and (max-width: 768px) {
        font-size: 3vw;
      }
      @media only screen and (max-width: 580px) {
        font-size: 3vw;
      }
      @media only screen and (max-width: 425px) {
        font-size: 4.3vw;
      }
      @media only screen and (max-width: 375px) {
        font-size: 5.2vw;
      }
    }
  }
`;
