import React from "react";
import { $NosValeurs, $valeurElement } from "./SC_NosValeurs";
import { ReactSVG } from "react-svg";
import { nosValeurData } from "./nosValeursData";

const NosValeurs = () => {
  return (
    <$NosValeurs id="valeurs">
      <h3 className="title">{nosValeurData.title}</h3>
      <p>{nosValeurData.description}</p>
      <div className="container-box">
        {nosValeurData.list.map((valeur, i) => (
          <$valeurElement
            key={`valeurs-${i}`}
            backgroundImg={valeur.backgroundImg}
            color={valeur.color1}
          >
            <div className="content">
              <ReactSVG
                className="icon-nos-valeur"
                src={`../../../SVG/${valeur.icon}`}
              ></ReactSVG>
              <span className="description"> {valeur.description}</span>
            </div>
          </$valeurElement>
        ))}
      </div>
    </$NosValeurs>
  );
};

export default NosValeurs;
