import PropTypes from "prop-types"
import React from "react"
import { Container, Row } from "react-bootstrap"

import "./jobList.scss"
import { ReactSVG } from "react-svg"

const getOfferItemsBlock = (offerList) => {
  if (offerList === undefined || offerList === null || offerList.length === 0)
    return (
      <p className="textAlignCenter">
        Aucune(s) offre(s) disponible(s)/trouvée(s) en ce moment.
      </p>
    )

  const offerListHtmlContent = offerList
    .sort(function (a, b) {
      return new Date(b.dateAffichage) - new Date(a.dateAffichage)
    })
    .map((offer, index) => {
      //slice offer
      return (
        <a
          key={`offerList-${index}`}
          href={`/offres/${offer.categorie}/${offer.postUrlPermLink}`}
          className="jobCart"
        >
          <span className="jobTitle"> {offer.titreOffre} </span>
          <div className="moreDetails">
            <ReactSVG
              className="iconDetailsArrow"
              src={`../../../../SVG/Trace_729.svg`}
            ></ReactSVG>
            <span className="text"> Plus de détails </span>
          </div>
        </a>
      )
    })

  return (
    <Row id="jobList" className="jobList">
      <h4>
        Voici les opportunités de carrière disponibles actuellement chez STAS :
      </h4>
      {offerListHtmlContent}
    </Row>
  )
}

function OfferList(props) {
  console.log(props, "props")
  return <Container fluid>{getOfferItemsBlock(props.offerList)}</Container>
}

OfferList.propTypes = {
  list: PropTypes.array,
}

OfferList.defaultProps = {
  list: [],
}

export default OfferList
