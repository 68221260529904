import styled from "styled-components"
import img from "../../../../static/batisseStas.webp"
import imgTablet from "../../../../static/batisseStas-tablet.webp"
import imgMobile from "../../../../static/batisseStas-mobile.webp"

export const $FeatureImage = styled.div`
  height: 70vh;
  float: left;
  width: 100%;
  margin: 0px 30px 20px 0;
  position: relative;

  @media only screen and (max-width: 1024px) {
    height: auto;
  }

  .feature-image {
    height: 100%;
    width: 100%;
    background: url(${img}) no-repeat center;

    background-size: cover;
    @media only screen and (max-width: 1024px) {
      background: url(${imgTablet}) no-repeat center;
      background-size: cover;
      height: 23em;
    }
    @media only screen and (max-width: 425px) {
      background: url(${imgMobile}) no-repeat center;
      background-size: cover;
      height: 20em;
    }
  }

  .Overlay {
    background-color: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    opacity: 0.15;
  }

  .line-curve {
    position: absolute;
    bottom: 0;
    width: 120em;
    right: 2em;
    @media only screen and (max-width: 1024px) {
      display: none;
    }
    div {
      svg {
        height: auto;
        width: 100%;
      }
    }
  }

  .features-container-text {
    display: flex;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    @media only screen and (max-width: 1024px) {
      display: block;
      position: relative;
    }
    .svg-features {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;

      svg {
        position: absolute;
        right: 0;

        @media only screen and (max-width: 1024px) {
          width: 40%;
        }

        @media only screen and (max-width: 1024px) {
          right: -25%;
          width: 60%;
          top: -80%;
          height: 50em;
          z-index: 1;
        }

        @media only screen and (max-width: 425px) {
          right: -30%;
        }
        @media only screen and (max-width: 375px) {
          right: -60%;
        }
      }
    }
  }
  .block-text-title {
    background-color: rgba(0, 23, 58, 0.79);
    width: 43%;
    z-index: 1;
    padding: 2em 0em 2em 4em;
    position: absolute;
    bottom: -15%;
    text-align: left;
    line-height: 1.1;
    color: #fff;
    @media only screen and (max-width: 1440px) {
      padding: 1.5em 0em 1.5em 2em;
    }

    @media only screen and (max-width: 1024px) {
      background-color: rgba(0, 23, 58, 0.95);
      width: 100%;
      position: relative;
    }

    h1 {
      z-index: 2;
      color: #fff;
      font-weight: bold;
      strong {
        font-weight: bold;

        &.color1 {
          color: #4fbec7;
        }

        &.color2 {
          color: #c6c287;
        }
      }

      :nth-child(1) {
        font-size: 4.5em;
        @media only screen and (max-width: 1440px) {
          font-size: 3.5em;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 3.5em;
        }
        @media only screen and (max-width: 1024px) {
          font-size: 3em;
        }
      }
    }
    p {
      padding: 1em 1em 1em 0;
    }
    .button {
      text-decoration: none;
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 1em;
      width: 14em;
      text-align: center;
      background: transparent
        linear-gradient(105deg, var(--unnamed-color-eb5536) 0%, #ff886f 100%) 0%
        0% no-repeat padding-box;
      background: transparent linear-gradient(105deg, #eb5536 0%, #ff886f 100%)
        0% 0% no-repeat padding-box;
      border-radius: 50px;
      padding: 0.8em 1.6em;
      margin-top: 1em;
      @media only screen and (max-width: 1024px) {
        margin: 1em auto;
      }
    }
  }
  .over-the-world {
    width: 62%;
    position: absolute;
    right: 0;
    bottom: -10%;
    background-color: #fa280c;
    padding: 1em 1em 1em 6em;
    color: #fff;
    @media only screen and (max-width: 1024px) {
      display: block;
      position: relative;
      margin-left: auto;
      width: 90%;
      padding: 1em;
    }

    p {
      position: relative;
      z-index: 2;
      width: 95%;
      @media only screen and (min-width: 1024px) {
        width: 90%;
      }
      @media only screen and (min-width: 1024px) {
        width: 60%;
      }
      @media only screen and (min-width: 1440px) {
        width: 60%;
      }
    }
    .visit-stas {
      z-index: 3;
      position: absolute;
      padding: 10px 20px;
      /* border: 4px solid rgba(0, 23, 58, 1); */
      background-color: #ecedf3;
      color: rgba(0, 23, 58, 1);
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  /* .corner {
    div {
      position: absolute;
      bottom: 0;
      width: 46em;
      height: 100;
      left: 0;
      svg {
        height: 100%;
        width: 100%;
        opacity: 0.6;
      }
    }
  } */
`
