import PropTypes from "prop-types"
import React from "react"
import { $FeatureImage } from "./SC_FeatureImage"
import { ReactSVG } from "react-svg"

const FeatureImage = React.memo(({ headerImageSrc, allHeaderImageSrc }) => {
  return (
    <$FeatureImage backGroundImage={allHeaderImageSrc}>
      <div className="feature-image"></div>
      <div className="Overlay"></div>
      {/* <ReactSVG
        className="LineCurve"
        src="../../../SVG/ligne-courbe-machinerie.png"
      ></ReactSVG> */}
      {/* <img
        className="line-curve"
        src="../../../ligne-courbe-machinerie@2x.png"
      /> */}
      <div className="features-container-text">
        <div className="block-text-title">
          <h1 className="">
            Des talents <br />
            sans frontière
          </h1>
          <p>
            STAS est une entreprise québécoise reconnue mondialement pour le
            développement, la conception, la fabrication et la commercialisation
            d’équipements industriels de haute technologie pour les alumineries.
          </p>
          {/* <a className="button" href="#offer">
          Voir les offres
        </a> */}
        </div>
        <div className="over-the-world">
          <p>
            Nos équipements sur-mesure permettent à des milliers d’employés
            d’alumineries réparties dans 40 pays à travers le monde de
            travailler de façon plus sécuritaire et efficace.
          </p>
          <a href="https://www.stas.com/" className="visit-stas">
            Apprenez-en plus sur nos équipements à STAS.COM
          </a>
        </div>
        <ReactSVG
          className="svg-features"
          src={`../../../../SVG/squares-features.svg`}
        ></ReactSVG>
      </div>
    </$FeatureImage>
  )
})

FeatureImage.propTypes = {
  headerImageSrc: PropTypes.string,
}

FeatureImage.defaultProps = {
  headerImageSrc: ``,
}

export default FeatureImage
