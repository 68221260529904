import styled from "styled-components";
export const SC_Avantages = styled.div`
  padding: 5em 16%;
  margin: 0 auto;
  /* height: 57.3em; */
  width: 100%;
  position: relative;
  /* overflow: hidden; */
  background-color: #3b466a;

  &:after {
    z-index: 0;
    content: "";
    background: url(../../../../STAS-image-desktop.webp) no-repeat center;
    background-size: 100%;
    width: 32em;
    height: 37em;
    position: absolute;
    top: -11em;
    left: 0;
    @media only screen and (max-width: 1024px) {
      font-size: 0.5rem;
    }

    @media only screen and (max-width: 768px) {
      background: url(../../../../STAS-image-tablet.webp) no-repeat center;
      background-size: 100%;
    }
    @media only screen and (max-width: 425px) {
      background: url(../../../../STAS-image-mobile.webp) no-repeat center;
      background-size: 100%;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 5em 5%;
    height: auto;
  }

  div {
    z-index: 1;
    h3 {
      text-align: center;
      z-index: 1;
      color: #fff;
      width: 100%;
      margin: 1em auto;
      position: relative;
      text-transform: uppercase;
      /* font-weight: bold;
      font-size: 2.5rem;
      @media only screen and (max-width: 768px) {
        font-size: 2rem;
      } */
    }
    p {
      font-size: 16px;
      width: 55%;
      margin: 3em 0;
      border-radius: 14px;

      @media only screen and (max-width: 1024px) {
        padding: 0.5em;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    .container {
      padding: 0;
      max-width: 100%;
      position: relative;

      .row {
        justify-content: center;

        .colon {
          background: #fff;
          padding: 0;
          position: relative;
          margin: 0.3%;
          max-width: 48%;
          @media only screen and (max-width: 1024px) {
            max-width: 80%;
          }
          @media only screen and (max-width: 768px) {
            max-width: 90%;
          }
        }
      }
    }
  }

  .description-container {
    transition: 1s;
    z-index: 1;
    display: ${(props) => (props.openState ? "block" : "none")};
    /* display: block ; */
    width: 100%;
    height: auto;
    padding: 1em;
    background-color: #fff;
    /* font-size: 1.2em; */

    .close {
      padding: 10px;
    }
  }
`;
export const SC_AvantagesUnite = styled.div`
  position: relative;
  cursor: pointer;
  height: 5em;
  background: ${(props) =>
    props.color1 && props.color2
      ? `transparent linear-gradient(90deg, ${props.color1} 0%, ${props.color2} 100%) 0% 0% no-repeat `
      : ""};

  @media only screen and (max-width: 768px) {
    padding-top: 10%;
  }

  &:hover {
    background: ${(props) => (props.color1 ? props.color1 : "")};
  }

  .inner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0px 10px 20px #2e334715;
    height: 100%;
    display: flex;
    align-items: center;
    color: #121f48;
    font-weight: 600;
    width: 100%;
    /* justify-content: space-between; */

    /* @media only screen and (max-width: 768px) {
      flex-direction: column;
    } */

    .icon-advantage {
      fill: #fa280c;
      display: flex;
      align-items: center;
      padding: 1em 0 1em 1em;
      @media only screen and (max-width: 768px) {
        padding: 0 0 0 1.5em;
      }
      div {
        svg {
          height: auto;
          max-height: 3em;
          width: 2.2em;
        }
      }
    }
    span {
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      font-weight: 700;
      line-height: 1.2;
      padding: 0 1em;
    }
    .more-description-icon {
      margin-right: 1em;
      height: 2em;
      width: 2em;
      position: relative;

      .part-2 {
        position: absolute;
        top: 0;
        padding: 0 0.3em;
        transform: rotate(90deg);
        transition: 0.5s;

        &.open {
          transform: rotate(0);
        }
      }
      div {
        svg {
          height: 2em;
          width: 100%;

          /* @media only screen and (max-width: 768px) {
            height: 3em;
            width: auto;
            margin: 0 2em 0 0;
          } */
        }
      }
    }
  }
`;
