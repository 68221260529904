import React from "react";
import { ReactSVG } from "react-svg";
import { $WorkHere } from "./SC_WhyWorkHere.js";
import VideoJS from "./video.js";
import { whyList } from "./whyWorkHereData.js";

export const WhyWorkHere = () => {
  return (
    <$WorkHere>
      <ReactSVG
        className="penta-background"
        src="../../../../SVG/penta.svg"
      ></ReactSVG>
      <div className="main-container">
        <div className="title-container-video">
          <h3>Qui est STAS?</h3>
        </div>
        <div className="video">
          <VideoJS />
        </div>
        <div className="title-container">
          <h3>Pourquoi travailler chez STAS</h3>
          <span>
            Voici les 4 principales raisons pour lesquelles les gens aiment
            bâtir une carrière chez STAS.
          </span>
        </div>
        <div className="why-list">
          {whyList.map((elements) => (
            <div
              key={`why-${elements.number}`}
              className={`list list-${elements.number}`}
            >
              <span className="number">{elements.number}</span>
              <div className="content-box">
                <h4>{elements.title}</h4>
                {elements.subTitle && (
                  <span className="subTitle"> {elements.subTitle}</span>
                )}

                <p>{elements.paragraph1}</p>
                {elements.paragraph2 && <p> {elements.paragraph2}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
      <ReactSVG
        className="alveol-background"
        src="../../../../SVG/Groupe685.svg"
      ></ReactSVG>
    </$WorkHere>
  );
};
