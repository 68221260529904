export const nosValeurData = {
  title: "Nos valeurs",
  description:
    "Notre travail est guidé par les valeurs de respect, famille, créativité, plaisir et satisfaction client. Tu veux voir comment ces valeurs se vivent au quotidien?",
  list: [
    {
      icon: "Groupe_727.svg",
      description:
        "Nous montrons de la considération les uns envers les autres et nous reconnaissons les différences de chacun.",
      backgroundImg: "Trace_853",
    },

    {
      icon: "Groupe_728.svg",
      description:
        "Notre activité est guidée par notre créativité et notre esprit d’innovation. En comprenant mieux les attentes des clients et en travaillant sans cesse pour innover et améliorer les produits et les procédés, STAS continuera d’être un des principaux fabricants pour l’industrie de l’aluminium.",
      color1: "#FE2B09",
      backgroundImg: "Trace_854",
    },
    {
      icon: "Groupe_729.svg",
      description:
        "Sans plaisir au travail, nous ne serions pas en mesure d’atteindre nos objectifs. Le plaisir que nous avons en équipe est l’une des principales raisons pour lesquelles nous nous rendons au travail chaque jour.",
      backgroundImg: "Trace_853",
    },
    {
      icon: "Groupe_730.svg",
      description:
        "STAS est une entreprise familiale et nous partageons aussi un sens commun de la famille. Nous fournissons à nos employés des conditions qui respectent leurs besoins tels que l’équilibre travail-famille.",
      color1: "#FE2B09",
      backgroundImg: "Trace_854",
    },
    {
      icon: "Groupe_731.svg",
      description:
        "Nous comprenons les besoins et les attentes de nos clients et faisons tout notre possible pour les satisfaire dans tout ce que nous faisons. Nous soutenons le succès de nos clients en créant une valeur exceptionnelle grâce à des solutions de produits innovantes.",
      backgroundImg: "Trace_853",
    },
  ],
};
