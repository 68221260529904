import React, { useState } from "react"
import ReactPlayer from "react-player/youtube"

export const VideoSlider = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const videos = [
    {
      url: "https://www.youtube.com/watch?v=MfGE96ZDq3E",
      thumbnail: "../Stas.webp",
    },
    {
      url: "https://www.youtube.com/watch?v=Nu9AoZs1OXU",
      thumbnail: "../stas-2.webp",
    },
  ]

  const handleVideoEnd = () => {
    if (currentVideoIndex === videos.length - 1) {
      setCurrentVideoIndex(0)
    } else {
      setCurrentVideoIndex(currentVideoIndex + 1)
    }
  }

  return (
    <>
      <img
        class="btn"
        onClick={handleVideoEnd}
        src={"../iconmonstr-arrow.png"}
      />
      <img
        class="btn right"
        onClick={handleVideoEnd}
        src={"../iconmonstr-arrow.png"}
      />
      <ReactPlayer
        light={videos[currentVideoIndex].thumbnail}
        controls={true}
        width="100%"
        playing={true}
        height="100%"
        url={videos[currentVideoIndex].url}
        onEnded={handleVideoEnd}
      />
    </>
  )
}

export default VideoSlider
