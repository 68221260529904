export const whyList = [
  {
    number: "01",
    title: "Notre réelle empreinte internationale",
    paragraph1:
      "Nous avons des projets partout à travers le monde puisque 85% de notre production est exportée à l’international! Que tu sois chez un client pour l’installation de nos équipements ou bien que tu sois au Saguenay, tu auras un réel impact à l’international. Ta carrière internationale commence donc ici, et nous t’ouvrons des opportunités infinies. STAS est une PME d’envergure internationale!",
  },
  {
    number: "02",
    title: "La fierté et l’impact de nos réalisations",
    paragraph1:
      "Nous concevons des équipements technologiques qui permettent de rendre le travail plus sécuritaire pour des milliers d’opérateurs, car les normes de santé et de sécurité ne sont pas les mêmes d’un pays à l’autre. Ton travail aura donc un impact direct sur la vie de ces gens.",
    paragraph2:
      "Nos collègues le soulignent tous avec fierté lorsqu’ils sont de retour de chez nos clients à l’étranger. Nos équipements sont encore performants même après 30 ans en opération. Nous sommes tous très fiers de la qualité de notre travail !",
  },
  {
    number: "03",
    title: "La vie STAS",
    paragraph1:
      "STAS c’est d’abord une affaire de famille. Nous souhaitons offrir et maintenir les plus hauts standards du marché au niveau de la conciliation travail-famille. Nous savons que tu as une vie à l'extérieur de nos bureaux et nous voulons te donner les moyens pour t'aider à jongler avec les deux.",
    paragraph2:
      "Travailler chez STAS c’est bien sûr avoir du plaisir, mais c’est surtout une culture d’entreprise où la contribution de chacun fait une différence.",
  },
  {
    number: "04",
    title: "Le travail en équipe multidisciplinaire",
    subTitle: "Collaboration + Compétences = Dépassement²",
    paragraph1:
      "Ça semble bien simple comme équation, mais c’est vrai. Chez nous, les employés résolvent des problèmes complexes en tirant profit de l’expertise de chacun. Dans la même équipe, il peut y avoir des collègues des ventes, de l’ingénierie mécanique et électrique, de la programmation, de l’usine ou de l’installation. Quand tu conçois un équipement, tu as la possibilité de le voir en fabrication dans l'atelier et le suivre jusqu'à sa mise en service en usine. Ça donne un sens et du concret à son travail",
  },
];
