import React, { useRef } from "react"
import SEO from "../components/seo"
import BaseLayout from "../components/BaseLayout"
import FeatureImage from "../components/body/featureImage/FeatureImage"
import OfferList from "../components/dependentComponent/offerList/OfferList"
import "../components/styles/layout.scss"
// import "bootstrap/dist/css/bootstrap.min.css"
import clientData from "../client/data/data.json"
import GetRandomInt from "../components/dependentComponent/functions/getRandomNumber"
import { $Body } from "../styles/SC_Body"
import Avantages from "../components/body/avantage/Avantages"
// import "swiper/swiper.scss";
import CandidatureSpontanee from "../components/body/candidatureSpontanee/CandidatureSpontanee"
import { WhyWorkHere } from "../components/body/whyWorkHere/whyWorkHere"
import NosValeurs from "../components/body/nosValeur/NosValeurs"

function HomePage({ pageContext: { fechedOfferList } }) {
  const childRef = useRef()
  const [offerList] = React.useState(fechedOfferList)

  const [imgUrl, setImgUrl] = React.useState("")

  const headerImageHomePage = () => {
    if (imgUrl === "") {
      let imageNumber = GetRandomInt(2)
      let imageUrl = `/${clientData.featureImage[imageNumber]}`
      setImgUrl(imageUrl)
      return imageUrl
    } else {
      return imgUrl
    }
  }

  return (
    <>
      <BaseLayout>
        <SEO
          description="Plusieurs emplois disponibles au saguenay dans les domaines du génie, de la conception, programmation, R&D, etc. Présent dans plus de 40 pays à travers le monde, STAS est reconnu mondialement pour ces équipements !"
          title="EMPLOI STAS | Des talents sans frontière"
        />

        <$Body>
          <FeatureImage
            headerImageSrc={headerImageHomePage()}
            allHeaderImageSrc={clientData.featureImage}
          />
          <div id="jobList" className="offer-list">
            <div className="sub-title">
              <div className="sub-t">
                <h2>Nos offres d’emploi</h2>
              </div>
              <p>
                Chez STAS, tu auras la chance de voir concrètement l’impact de
                ton implication dans des projets au sein d’une équipe
                multidisciplinaire et ce, peu importe ton poste. Ici, tous les
                employés font la différence !
              </p>
              <div className="cta-container-offres-demplois">
                {/* <p>Découvrez nos offres alléchantes par vous-même !</p> */}
                <a
                  href="https://stasinc.recruitee.com/"
                  className="button-offres-demplois"
                >
                  Découvrez nos nouvelles opportunités d'emploi en cliquant ici 
                </a>
              </div>
            </div>
            {/* <div className="container-jobs">
              <OfferList offerList={offerList} />
            </div> */}
          </div>
          <div className="image-groups"></div>
          {/* <FlexiJobs /> */}
          <CandidatureSpontanee childRef={childRef} />
          <WhyWorkHere />
          <Avantages />
          <NosValeurs />
          {/* <NosUsines /> */}
          {/* <SwiperVideo /> */}
        </$Body>
      </BaseLayout>
    </>
  )
}

export default HomePage
