const avantagesData = {
  avantages: "Voici d’autres avantages de rejoindre l’équipe",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec egestas risus nisl, eu luctus arcu laoreet id. Aliquam ultricies dolor non elit tempor, ac tempor eros sagittis. Quisque ante augue, tristique nec molestie vel, tristique sit amet tortor.",

  list: [
    {
      icon: "Groupe_747.svg",
      text: "Vendredi pm de congé",
      description:
        "Chez STAS, nous offrons un horaire d’été à l’année. Nous commençons notre fin de semaine avant tout le monde! Golf? Magasinage? Motoneige? Vélo? Let’s go!",
      color1: "#FFFFFF",
      color2: "#FFFFFF",
    },

    {
      icon: "Groupe_746.svg",
      text: "Possibilités de voyager",
      description:
        "Plusieurs postes offrent la possibilité de voyager partout dans le monde dans le cadre de votre travail. Le job de rêve pour quelqu’un qui aime l’aventure!",
      color1: "#FFFFFF",
      color2: "#FFFFFF",
    },

    {
      icon: "armchair.svg",
      text: "Environnement de travail",
      description:
        "Nous savons que notre environnement de travail est un élément la clé de la satisfaction et de la performance de nos employés. Nous avons de beaux bureaux neufs avec un atelier d’une propreté exemplaire. Une seule visite saura te convaincre ! ",
      color1: "#FFFFFF",
      color2: "#FFFFFF",
    },
    {
      icon: "Groupe_716.svg",
      text: "Salaires et bonification",
      description:
        "Nous sommes soucieux d’offrir des salaires concurrentiels qui sont revus annuellement pour tous nos employés. Des bonus de performance sont également attribués à tous les employés selon le rendement annuel de l’entreprise. Nous partageons le fruit de nos efforts collectifs !",
      color1: "#FFFFFF",
      color2: "#FFFFFF",
    },

    {
      icon: "Groupe_742.svg",
      text: "Assurances collectives et régime de retraite",
      description:
        "Tu bénéficieras d’un programme d’assurances collectives complet après seulement 30 jours en poste. C’est avec plaisir que STAS cotise dans ton régime de retraite.",
      color1: "#FFFFFF",
      color2: "#FFFFFF",
    },

    {
      icon: "Groupe_748.svg",
      text: "Horaire flexible",
      description:
        "Nous comprenons la réalité de la vie quotidienne, c’est pourquoi nous offrons un horaire flexible pour s’ajuster à vous !",
      color1: "#FFFFFF",
      color2: "#FFFFFF",
    },
    {
      icon: "Groupe_743.svg",
      text: "Télétravail",
      description:
        "Bien que nous ayons de magnifiques bureaux fraichement aménagés, le télétravail fait maintenant partie de notre culture d'entreprise et nous l'offrons à tous les postes qui le permettent. Nous avons également prévu un budget de dépense pour que vous soyez aussi bien équipé à la maison qu'au bureau.",
      color1: "#FFFFFF",
      color2: "#FFFFFF",
    },
    {
      icon: "Groupe_799.svg",
      text: "Semaine de 4 jours",
      description:
        "Tu aimerais condenser tes heures sur 4 jours semaine ? Chez STAS, c'est maintenant possible ! Imagine avoir des longues fins de semaine de trois jours à l'année ! Parfait pour partir au chalet, voir sa famille ou pratiquer vos activités préférées.",
      color1: "#FFFFFF",
      color2: "#FFFFFF",
    },
    {
      icon: "sucette.svg",
      text: "NOUVEAUTÉ!!! Programme privilège garderie",
      description:
        "Nous savons qu’il peut être difficile d’obtenir une place en garderie et que cela peut devenir rapidement un casse-tête pour les familles qui travaillent.  C’est pourquoi STAS offre maintenant à ses employés des places prioritaires en garderie disponibles pour leurs enfants dans tout le réseau de La grande famille des étoiles (Chicoutimi, Jonquière, Lac-Saint-Jean). On peut dire que c’est un avantage qui apportera la tranquillité d'esprit à votre famille !",
      color1: "#FFFFFF",
      color2: "#FFFFFF",
    },
  ],
};
export default avantagesData;
