import styled from "styled-components"

export const $Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  .offer-list {
    padding: 7em 2em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      font-size: 1rem;
      width: 26em;
      height: 30em;
      top: -5em;
      right: -2em;
      background: url(../../../stas-working-desktop.webp) no-repeat center
        center;
      background-size: cover;
      position: absolute;
      @media only screen and (max-width: 1440px) {
        font-size: 0.65em;
      }
      @media only screen and (max-width: 768px) {
        background: url(../../../stas-working-desktop.webp) no-repeat center
          center;
        background-size: cover;
        font-size: 0.7em;
        position: relative;
        top: 0;
        right: 0;
      }
      @media only screen and (max-width: 425px) {
        font-size: 0.6em;
      }
      @media only screen and (max-width: 375px) {
        font-size: 0.5em;
      }
      /* @media only screen and (max-width: 768px) {
        font-size: 1em;
        position: relative;
        top: 0;
        right: 0;
      } */
    }
    @media only screen and (max-width: 768px) {
      margin: 0;
      padding: 2em;
    }
    .cta-container-offres-demplois {
      margin-top: 1em;
      display: flex;
      width: 100%;
      align-items: center;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
      p {
        width: auto !important;
        display: block;
        font-size: 1.3em;
      }
      .button-offres-demplois {
        text-decoration: none;
        color: #000;
        font-size: 1.3em;
        /* margin: 1em; */
        border: #fa280c 5px solid;
        padding: 0.5em 1em;
        height: fit-content;
        background-color: transparent;
      }
    }
    .sub-title {
      width: 75%;
      margin: 1em;
      display: flex;
      text-align: left;
      flex-direction: column;
      align-items: baseline;
      /* margin-bottom: -3em; */
      @media only screen and (max-width: 768px) {
        text-align: center;
        width: 90%;
      }
      .sub-t {
        font-weight: bold;
        @media only screen and (max-width: 768px) {
          text-align: center;
          width: 100%;
        }
        h2 {
          color: #fa280c;
          font-size: 3em;
          font-weight: 600;
        }
      }
      p {
        margin: 1em 0;
        width: 65%;
        @media only screen and (max-width: 768px) {
          width: 95%;
        }
      }
    }
    .container-jobs {
      width: 75%;
      padding: 2em;
      background-color: #ecedf3;
      position: relative;
      top: 100%;
      transform: translateY(6em);

      @media only screen and (max-width: 768px) {
        width: 90%;
        padding: 0em;
      }
    }
  }
  .image-groups {
    width: 100%;
    height: 75vh;
    background: url("../../../employeestas.webp") no-repeat center center;
    background-size: cover;
    @media only screen and (max-width: 768px) {
      height: 35vh;
      background: url("../../../employeestas-tablet.webp") no-repeat center
        center;
    }

    @media only screen and (max-width: 425px) {
      height: 45vh;
      background: url("../../../employeestas-mobile.webp") no-repeat center
        center;
    }
  }
`
