import React from "react";
import { SC_Avantages, SC_AvantagesUnite } from "./SC_Avantages";
import { Container, Row, Col } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import avantagesData from "./avantagesData";

const Avantages = () => {
  const [openState, setOpenState] = React.useState(false);

  return (
    <SC_Avantages id="avantages" openState={openState}>
      <div className="background" />
      <div>
        <h3>{avantagesData.avantages}</h3>
        {/* <p>{avantagesData.description}</p> */}
        <Container>
          <Row>
            {avantagesData.list.map((avantage, i) => {
              return (
                <Col
                  key={`avantages${i}`}
                  className="colon"
                  sm={12}
                  lg={6}
                  xs={12}
                  md={12}
                  onClick={() => setOpenState(!openState)}
                >
                  <SC_AvantagesUnite
                    color1={avantage.color1}
                    color2={avantage.color2}
                  >
                    <div className="inner">
                      <ReactSVG
                        className="icon-advantage"
                        src={`../../../SVG/${avantage.icon}`}
                      ></ReactSVG>
                      <span>{avantage.text}</span>
                      <div className="more-description-icon">
                        <ReactSVG
                          className="part-1"
                          src="../../../SVG/Groupe_740.svg"
                        ></ReactSVG>
                        <ReactSVG
                          className={`part-2 ${openState ? "open" : ""}`}
                          src="../../../SVG/Groupe_165.svg"
                        ></ReactSVG>
                      </div>
                    </div>
                  </SC_AvantagesUnite>
                  <div className="description-container ">
                    {avantage.description}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </SC_Avantages>
  );
};

export default Avantages;
